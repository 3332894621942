var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "title"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "title.".concat(k),
                    "name": "".concat(i, " Title"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var errors = _ref2.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Title")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Title")
                        },
                        model: {
                          value: _vm.form.title[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.title, k, $$v);
                          },
                          expression: "form.title[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "description"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "description.".concat(k),
                    "name": "Description ".concat(k)
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref3) {
                      var errors = _ref3.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "Description ".concat(i)
                        }
                      }, [_c('b-form-textarea', {
                        attrs: {
                          "placeholder": "Description ".concat(i),
                          "rows": "4"
                        },
                        model: {
                          value: _vm.form.description[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.description, k, $$v);
                          },
                          expression: "form.description[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "vid": "description",
            "name": "Description"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between mb-1"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" Images: ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.addImage
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)])], 1), _c('b-row', [_vm._l(_vm.form.uuids, function (item, index) {
          return [_c('b-col', {
            key: "".concat(item.uuid).concat(index),
            staticClass: "album-custom",
            attrs: {
              "cols": "12",
              "lg": "4",
              "md": "6"
            }
          }, [index > 0 ? _c('svg', {
            staticClass: "delete-icon",
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "viewBox": "0 0 448 512",
              "width": "20px",
              "height": "20px",
              "fill": "red"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImage(index);
              }
            }
          }, [_c('path', {
            attrs: {
              "d": "M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
            }
          })]) : _vm._e(), _c('ValidationProvider', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref5) {
                var errors = _ref5.errors;
                return [_c('MediaLibraryAttachment', {
                  ref: "mediaComponent",
                  refInFor: true,
                  attrs: {
                    "max-items": 1,
                    "name": "avatar",
                    "headers": _vm.headers,
                    "routePrefix": "media-library",
                    "uploadDomain": _vm.baseURL,
                    "validation-rules": {
                      accept: ['image/webp'],
                      maxSizeInKB: 2048
                    },
                    "can_remove": false
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.getFiles($event, index);
                    }
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          }), _c('LocaleTabs', {
            staticClass: "mt-2",
            attrs: {
              "errors": _vm.formErrors,
              "name": "title"
            },
            scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
              return {
                key: "lang".concat(idx),
                fn: function fn(slotScope) {
                  return [_c('ValidationProvider', {
                    key: idx,
                    class: slotScope.selectedTab !== idx ? 'hidden' : '',
                    attrs: {
                      "vid": "title.".concat(k),
                      "name": "".concat(i, " Title"),
                      "rules": "required"
                    },
                    scopedSlots: _vm._u([{
                      key: "default",
                      fn: function fn(_ref6) {
                        var errors = _ref6.errors;
                        return [_c('b-form-group', {
                          attrs: {
                            "label": "".concat(i, " Title")
                          }
                        }, [_c('b-form-input', {
                          attrs: {
                            "placeholder": "".concat(i, " Title")
                          },
                          model: {
                            value: _vm.form.uuids[index].title[k],
                            callback: function callback($$v) {
                              _vm.$set(_vm.form.uuids[index].title, k, $$v);
                            },
                            expression: "form.uuids[index].title[k]"
                          }
                        }), _c('small', {
                          staticClass: "text-danger"
                        }, [_vm._v(_vm._s(errors.uuid_value))])], 1)];
                      }
                    }], null, true)
                  })];
                }
              };
            })], null, true)
          }), _c('div', {
            staticClass: "pt-5px"
          }, [_c('hr')])], 1)];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0 mt-1"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }