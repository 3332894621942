<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="`${i} Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Title`">
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="`${i} Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="`Description ${k}`"
                  >
                    <b-form-group :label="`Description ${i}`">
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="`Description ${i}`"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
              <ValidationProvider
                v-slot="{ errors }"
                :vid="`description`"
                :name="`Description`"
              >
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col cols="4">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <h5 class="mb-0">
                      Images:
                    </h5>
                    <b-button
                      variant="primary"
                      @click="addImage"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <template
                  v-for="(item, index) in form.uuids"
                >
                  <b-col
                    :key="`${item.uuid}${index}`"
                    class="album-custom"
                    cols="12"
                    lg="4"
                    md="6"
                  >
                    <svg
                      v-if="index > 0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="delete-icon"
                      width="20px"
                      height="20px"
                      fill="red"
                      @click="removeImage(index)"
                    >
                      <path
                        d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
                      />
                    </svg>
                    <ValidationProvider
                      v-slot="{ errors }"
                    >
                      <MediaLibraryAttachment
                        ref="mediaComponent"
                        :max-items="1"
                        name="avatar"
                        :headers="headers"
                        routePrefix="media-library"
                        :uploadDomain="baseURL"
                        @change="getFiles($event,index)"
                        :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 2048}"
                        :can_remove="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </ValidationProvider>
                    <LocaleTabs
                      class="mt-2"
                      :errors="formErrors"
                      name="title"
                    >
                      <template
                        v-for="(i,k,idx) in languages"
                        :slot="`lang${idx}`"
                        slot-scope="slotScope"
                      >
                        <ValidationProvider
                          :key="idx"
                          v-slot="{ errors }"
                          :class="slotScope.selectedTab!==idx?'hidden':''"
                          :vid="`title.${k}`"
                          :name="`${i} Title`"
                          rules="required"
                        >
                          <b-form-group :label="`${i} Title`">
                            <b-form-input
                              v-model="form.uuids[index].title[k]"
                              :placeholder="`${i} Title`"
                            />
                            <small class="text-danger">{{ errors.uuid_value }}</small>
                          </b-form-group>
                        </ValidationProvider>
                      </template>
                    </LocaleTabs>

<!--                    <AlbumImageNew-->
<!--                      v-model="form.uuids[index]"-->
<!--                      :index="index"-->
<!--                      :languages="languages"-->
<!--                    >-->
<!--                      <template-->
<!--                        v-if="index > 0"-->
<!--                        #delete_images-->
<!--                      >-->
<!--                        <svg-->
<!--                          xmlns="http://www.w3.org/2000/svg"-->
<!--                          viewBox="0 0 448 512"-->
<!--                          class="delete-icon"-->
<!--                          width="20px"-->
<!--                          height="20px"-->
<!--                          fill="red"-->
<!--                          @click="removeImage(index)"-->
<!--                        >-->
<!--                          <path-->
<!--                            d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"-->
<!--                          />-->
<!--                        </svg>-->
<!--                      </template>-->
<!--                    </AlbumImageNew>-->

<!--                    <AlbumImage-->
<!--                      v-model="form.images[index]"-->
<!--                      :languages="languages"-->
<!--                    >-->
<!--                      <template-->
<!--                        v-if="index > 0"-->
<!--                        #delete_images-->
<!--                      >-->
<!--                        <svg-->
<!--                          xmlns="http://www.w3.org/2000/svg"-->
<!--                          viewBox="0 0 448 512"-->
<!--                          class="delete-icon"-->
<!--                          @click="removeImage(index)"-->
<!--                        >-->
<!--                          <path-->
<!--                            d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"-->
<!--                          />-->
<!--                        </svg>-->
<!--                      </template>-->
<!--                    </AlbumImage>-->

                    <div class="pt-5px">
                      <hr>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0 mt-1">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import { baseURL } from '@/services/url.service'

import formMixin from '@/mixins/formMixin'
// eslint-disable-next-line import/extensions
import AlbumImageNew from "@/components/AlbumImageNew"
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment'

export default {
  components: {
    AlbumImageNew,
    MediaLibraryAttachment,
  },
  mixins: [formMixin],
  data() {
    return {
      itemRefs: [],
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      validationErrors: {
        uuid_value: 'The Avatar field is required',
      },
      // media: null,
      isReadyToSubmit: true,
      forms: [],
      image: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        status: 'active',
        uuids: [
          {
            uuid_value: null,
            title: {
              en: null,
              ar: null,
            },
          },
        ],
      },
      imageAfterUpload: [],
    }
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    // getUuid(uuid) {
    //   this.form.uuids.push(uuid)
    // },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = _.pick(data, [
            'status', 'uuids',
          ])
          this.form = {
            ...this.form,
            title: data.title_value,
            description: _.isArray(data.description_value) ? {
              en: null,
              ar: null,
            } : data.description_value,
            uuids: data.uuids,
          }
          let uuids = data.uuids
          let that = this

          uuids.forEach((el, index) => {
            let arr = []
            const obj = {
              attributes: el.media,
              clientValidationErrors: [],
              client_preview: el.media.original_url,
              upload: {},
            }
            arr.push(obj)
            this.$nextTick(function () {
              for (const ref in that.$refs) {
                if (ref === 'mediaComponent') {
                  that.$refs[ref][index].mediaLibrary.changeState(state => {
                    state.media = arr
                  })
                }
              }
            })
          })
        })
    },
    addImage() {
      this.form.uuids.push({
        uuid_value: null,
        title: {
          en: null,
          ar: null,
        },
      })
    },
    removeImage(index) {
      // this.form.uuids.splice(index, 1)
      this.form.uuids.splice(index, 1)
    },
    getFiles(e, index) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      const imageAfterUpload = this.imageAfterUpload[0]
      // eslint-disable-next-line prefer-destructuring
      this.form.uuids[index].uuid_value = Object.keys(imageAfterUpload)[0]
    },
  },
}
</script>
<style>
.delete-icon {
  position: absolute;
  top: 0;
  right: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.delete-icon:hover {
  opacity: 0.7;
}
.album-custom{
  position: relative;
  padding-top: 30px;
}
</style>
